// src/App.tsx
import React, { Suspense } from 'react';

import '@locales/index';

import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

import AppProvider from './hooks';
import Routes from './routes';

import { GlobalStyles } from './styles';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<p>Carregando...</p>}>
          <AppProvider>
            <Routes />
            <GlobalStyles />
            <ToastContainer />
          </AppProvider>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
