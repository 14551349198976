import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import {
  ImageBussinessDecisions,
  ImageFormUser,
  ImageLogotipo,
} from '@assets/images';
import {
  ButtonBlueGradient,
  ButtonOutline,
  HeaderBack,
  InputMask,
  InputSelect,
  InputText,
} from '@components/index';
import { ConfigValues } from '@config/index';
import { useSignUp } from '@hooks/signup';
import api from '@services/api';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { schemaLegal, schemaPhysical } from './schemaValidation';
import { IPhysicalFormData, ILegalFormData } from './typing';

import {
  Card,
  Container,
  Content,
  ContentTitle,
  ContentSubtitle,
  ContentAllCards,
  ContentAllCardsCardItem,
  ContentFormSignUp,
  ContentFormSignUpForm,
  ContentFormSignUpFormTermsOfUse,
  InternalLink,
} from './styles';

const PersonalData: React.FC = () => {
  const formLegalRef = useRef<FormHandles>(null);
  const formPhysicalRef = useRef<FormHandles>(null);
  const { signUpUserData, updateSignUpUserData } = useSignUp();
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLegalPerson, setIsLegalPerson] = useState<boolean>(
    signUpUserData.cnpj ? true : false,
  );

  const handleSubmitLegal = useCallback(async (data: ILegalFormData) => {
    try {
      setLoading(prevState => !prevState);

      formPhysicalRef.current?.reset();
      formLegalRef.current?.setErrors({});

      await schemaLegal.validate(data, {
        abortEarly: false,
      });

      updateSignUpUserData({
        name: data.legal_name,
        cnpj: data.legal_cnpj,
        email: data.legal_email,
        cellphone: data.legal_cellphone,
      });

      const {
        data: { data: responseUser },
      } = await api.post('/users', {
        name: data.legal_name,
        person_type: ConfigValues.user.person_type.legal_person,
        cpf: '',
        cnpj: data.legal_cnpj,
        sex: ConfigValues.user.sex.undefined,
        email: data.legal_email,
        cellphone: data.legal_cellphone,
        telephone: null,
        role: ConfigValues.user.role.provider,
        status: ConfigValues.user.status.active,
        company_size: '',
        access_level: ConfigValues.user.access_level.normal,
        subordinate_of: null,
        id_who_indicated: null,
        is_partner: true,
        image_url: '',
        accept_terms_of_use: true,
        gateway_customers_id: null,
      });

      let storagedUserData: any = localStorage.getItem(
        '@Rebox-Client:signup-data',
      );

      if (storagedUserData) {
        storagedUserData = JSON.parse(storagedUserData);
      }

      localStorage.setItem(
        '@Rebox-Client:signup-data',
        JSON.stringify({
          ...storagedUserData,
          id: responseUser.id,
        }),
      );

      push('/cadastro/endereco');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formLegalRef.current?.setErrors(errors);

        const {
          legal_name,
          legal_cnpj,
          legal_email,
          legal_cellphone,
          legal_company_size,
        } = errors;

        if (legal_name) notify(legal_name, 'error');
        if (legal_cnpj) notify(legal_cnpj, 'error');
        if (legal_email) notify(legal_email, 'error');
        if (legal_cellphone) notify(legal_cellphone, 'error');
        if (legal_company_size) notify(legal_company_size, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  const handleSubmitPhysical = useCallback(async (data: IPhysicalFormData) => {
    try {
      setLoading(prevState => !prevState);

      formLegalRef.current?.reset();
      formPhysicalRef.current?.setErrors({});

      await schemaPhysical.validate(data, {
        abortEarly: false,
      });

      updateSignUpUserData({
        name: data.physical_name,
        cpf: data.physical_cpf,
        email: data.physical_email,
        cellphone: data.physical_cellphone,
      });

      const {
        data: { data: responseUser },
      } = await api.post('/users', {
        name: data.physical_name,
        person_type: ConfigValues.user.person_type.physical_person,
        cpf: data.physical_cpf,
        cnpj: '',
        sex: ConfigValues.user.sex.undefined,
        email: data.physical_email,
        cellphone: data.physical_cellphone,
        telephone: null,
        role: ConfigValues.user.role.provider,
        status: ConfigValues.user.status.active,
        company_size: '',
        access_level: ConfigValues.user.access_level.normal,
        subordinate_of: null,
        id_who_indicated: null,
        is_partner: true,
        image_url: '',
        accept_terms_of_use: true,
        gateway_customers_id: null,
      });

      let storagedUserData: any = localStorage.getItem(
        '@Rebox-Client:signup-data',
      );

      if (storagedUserData) {
        storagedUserData = JSON.parse(storagedUserData);
      }

      localStorage.setItem(
        '@Rebox-Client:signup-data',
        JSON.stringify({
          ...storagedUserData,
          id: responseUser.id,
        }),
      );
      push('/cadastro/endereco');
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        formPhysicalRef.current?.setErrors(errors);

        const {
          physical_name,
          physical_cpf,
          physical_email,
          physical_cellphone,
        } = errors;

        if (physical_name) notify(physical_name, 'error');
        if (physical_cpf) notify(physical_cpf, 'error');
        if (physical_email) notify(physical_email, 'error');
        if (physical_cellphone) notify(physical_cellphone, 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    } finally {
      setLoading(prevState => !prevState);
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem('@Rebox-Client:signup-data');
  }, []);

  return (
    <Container>
      <HeaderBack />

      <Content>
        <ImageLogotipo width={200} height={50} />
        <ContentTitle>Que bom ter você aqui!</ContentTitle>

        <ContentSubtitle>
          Para prosseguir com seu cadastro precisamos te conhecer melhor.
          Preencha corretamente os dados abaixo.
        </ContentSubtitle>

        <ContentAllCards>
          <ContentAllCardsCardItem>
            <Card
              isLegalPerson={!isLegalPerson}
              onClick={() => {
                setIsLegalPerson(prevState => !prevState);
              }}
            >
              <p>Sou pessoa física</p>
            </Card>
          </ContentAllCardsCardItem>
          <ContentAllCardsCardItem>
            <Card
              isLegalPerson={isLegalPerson}
              onClick={() => {
                setIsLegalPerson(prevState => !prevState);
              }}
            >
              <p>Sou pessoa jurídica</p>
            </Card>
          </ContentAllCardsCardItem>
        </ContentAllCards>

        {!isLegalPerson ? (
          <ContentFormSignUp>
            <ContentFormSignUpForm
              ref={formPhysicalRef}
              onSubmit={handleSubmitPhysical}
            >
              <InputText
                name="physical_name"
                placeholder="Nome completo"
                alertVisible
              />

              <InputMask
                name="physical_cpf"
                placeholder="CPF"
                alertVisible
                mask="999.999.999-99"
              />

              <InputText
                name="physical_email"
                placeholder="E-mail"
                alertVisible
              />

              <InputMask
                name="physical_cellphone"
                placeholder="Whatsapp"
                alertVisible
                mask="55 (99) 9 9999-9999"
              />

              <ContentFormSignUpFormTermsOfUse>
                Ao continuar você concorda com nossos
                <InternalLink to="/politica-de-privacidade">
                  {' '}
                  termos de uso{' '}
                </InternalLink>{' '}
                e
                <InternalLink to="/politica-de-privacidade">
                  {' '}
                  políticas de privacidade
                </InternalLink>
                .
              </ContentFormSignUpFormTermsOfUse>

              <ButtonBlueGradient loading={loading}>
                Continuar
              </ButtonBlueGradient>
            </ContentFormSignUpForm>

            <ImageFormUser />
          </ContentFormSignUp>
        ) : (
          <ContentFormSignUp>
            <ContentFormSignUpForm
              ref={formLegalRef}
              onSubmit={handleSubmitLegal}
            >
              <InputText
                name="legal_name"
                placeholder="Razão social/Nome fantasia"
                alertVisible
              />

              <InputMask
                name="legal_cnpj"
                placeholder="CNPJ"
                alertVisible
                mask="99.999.999/9999-99"
              />

              <InputText name="legal_email" placeholder="E-mail" alertVisible />

              <InputMask
                name="legal_cellphone"
                placeholder="Whatsapp"
                alertVisible
                mask="(99) 9 9999-9999"
              />

              <ContentFormSignUpFormTermsOfUse>
                Ao continuar você concorda com nossos
                <InternalLink to="/"> termos de uso </InternalLink> e
                <InternalLink to="/"> políticas de privacidade</InternalLink>.
              </ContentFormSignUpFormTermsOfUse>

              <ButtonBlueGradient loading={loading}>
                Continuar
              </ButtonBlueGradient>
            </ContentFormSignUpForm>

            <ImageBussinessDecisions />
          </ContentFormSignUp>
        )}
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default PersonalData;
