import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { IconArrowLeftWhite } from '@assets/icons';
import { ImageLogotipo, ImageThanks } from '@assets/images';

import { IUser } from './typing';

import {
  Container,
  ContainerThanks,
  ContentTitle,
  Content,
  ContainerButtons,
  ContentBox,
  ContentSubtitle,
  ButtonAccess,
  TextButtonAccess,
} from './styles';

const Thanks: React.FC = () => {
  const { push } = useHistory();
  const [user, setUser] = useState<IUser>({} as IUser);

  useEffect(() => {
    const storagedUser = localStorage.getItem('@Rebox-Client:signup-data');
    if (storagedUser) {
      setUser(JSON.parse(storagedUser));
    }
  }, []);

  return (
    <Container>
      <Content>
        <ImageLogotipo width={200} height={50} />

        <ContentTitle>{`Parabéns, ${user.name}.`}</ContentTitle>
        <ContentTitle style={{ margin: 0 }}>
          Seu cadastro foi realizado com sucesso!
        </ContentTitle>

        {/* <ContentSubtitle>
          Enviamos para seu <strong>email</strong> o passo a passo para acessa à{' '}
          <strong>Área do Prestador</strong> e como receber os acionamentos
          através do <strong>Whatsapp</strong>.
        </ContentSubtitle>
        <ContentSubtitle>
          Acesse seu email e confira as informações.
        </ContentSubtitle>
        <ContentSubtitle>
          Será uma satisfação tê-lo como parceiro!
        </ContentSubtitle> */}

        <ContentBox>
          <ContainerThanks>
            <ImageThanks />
          </ContainerThanks>
          {/* <ContainerButtons>
            <ButtonAccess onClick={() => push('/')}>
              <TextButtonAccess>Acessar o sistema</TextButtonAccess>
              <IconArrowLeftWhite />
            </ButtonAccess>
          </ContainerButtons> */}
        </ContentBox>
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default Thanks;
