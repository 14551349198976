// src/config/base.ts

export default {
  baseURL: 'https://rebox2.com.br/v1.0',
  linkShared: 'https://rebox.com.br/nossos-planos?i=',
  clientBaseURL: 'https://rebox.com.br',
  providerBaseURL: 'https://prestador.rebox.com.br',
  emailContactRebox: 'faleconosco@rebox.com.br',
  phoneContactRebox: '5521983846769',
  whatsapp: {
    commercial: '551140033132',
    apiBaseURL: 'https://api.whatsapp.com',
  },
  viaCep: {
    baseUrls: {
      address: 'https://viacep.com.br/ws',
    },
  },
};
