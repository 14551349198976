/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { ImageHouse, ImageLogotipo } from '@assets/images';
import ButtonDefault from '@components/buttons/ButtonDefault';
import {
  ButtonBlueGradient,
  ButtonOutline,
  HeaderBack,
  InputMask,
  InputText,
} from '@components/index';
import api from '@services/api';
import apiViaCep from '@services/apiViaCep';
import { formatText } from '@utils/formatters';
import notify from '@utils/notify/toasts';

import { schemaMultipleAddress, schemaUniqueAddress } from './schemaValidation';
import { IAddressResponse, IMultipleAddress } from './typing';

import {
  Card,
  Container,
  Content,
  ContentAllCards,
  ContentAllCardsCardItem,
  ContentFormAddress,
  ContentFormAddressForm,
  ContentSubtitle,
  ContentTitle,
  GenericModal,
  SectionsCepOptions,
} from './styles';

const AddressData: React.FC = () => {
  const formAddress = useRef<FormHandles>(null);
  const { push } = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUniqueAddress, setIsUniqueAddress] = useState<boolean>(false);
  const [isformAddressEnabled, setIsFormAddressEnabled] = useState<boolean>(
    false,
  );
  const [
    isformModalAddressEnabled,
    setIsFormModalAddressEnabled,
  ] = useState<boolean>(false);
  const [isOpenAddressesModal, setIsOpenAddressesModal] = useState<boolean>(
    false,
  );

  const [uniqueCep, setUniqueCep] = useState<string>('');
  const [firstMultipleCep, setFirstMultipleCep] = useState<any>('');

  const [uniqueAddress, setUniqueAddress] = useState<IAddressResponse>(
    {} as IAddressResponse,
  );
  const [firstMultipleAddress, setFirstMultipleAddress] = useState<any>({});

  const [multipleAddresses, setMultipleAddresses] = useState<
    IMultipleAddress[]
  >([]);
  const [multipleCeps, setMultipleCeps] = useState<IMultipleAddress>({
    cep_id: 0,
    cep_value: '',
    street: '',
    city: '',
    number: '',
    complement: '',
    state: '',
    district: '',
  });

  const handleSubmitFormAddress = async () => {
    try {
      setIsLoading(currLoading => !currLoading);
      if (uniqueCep === '' && !isUniqueAddress) {
        await schemaUniqueAddress.validate(uniqueCep, {
          abortEarly: false,
        });
      }
      if (firstMultipleCep === '' && isUniqueAddress) {
        await schemaMultipleAddress.validate(firstMultipleCep, {
          abortEarly: false,
        });
      }
      const ordenedUniqueAddress = {
        cep_id: 1,
        cep_value: uniqueAddress.cep,
        street: uniqueAddress.logradouro,
        country: 'BR',
        city: uniqueAddress.localidade,
        complement: uniqueAddress.complemento,
        state: uniqueAddress.uf,
        district: uniqueAddress.bairro,
        number: uniqueAddress.number,
      };

      const ordenedMultipleAddresses = multipleAddresses.map((mult: any) => ({
        cep_id: mult.cep_id,
        cep_value: mult.cep ? mult.cep : mult.cep_value,
        street: mult.logradouro ? mult.logradouro : mult.street,
        city: mult.localidade ? mult.localidade : mult.city,
        complement: mult.complemento ? mult.complemento : mult.complement,
        country: 'BR',
        state: mult.uf ? mult.uf : mult.state,
        district: mult.bairro ? mult.bairro : mult.district,
        number: mult.number,
      }));

      if (multipleAddresses.length === 0) {
        localStorage.setItem(
          '@Rebox-Client:unique-address-data',
          JSON.stringify(ordenedUniqueAddress),
        );

        const storageUser = localStorage.getItem('@Rebox-Client:signup-data');
        let id = '';
        if (storageUser) {
          id = JSON.parse(storageUser).id;
        }

        await api.post('/users/address', {
          users_id: id,
          country: ordenedUniqueAddress.country,
          state: ordenedUniqueAddress.state,
          city: ordenedUniqueAddress.city,
          neighborhood: ordenedUniqueAddress.district,
          zip_code: ordenedUniqueAddress.cep_value,
          street: ordenedUniqueAddress.street,
          number: ordenedUniqueAddress.number,
          complement: ordenedUniqueAddress.complement,
        });
      } else {
        localStorage.setItem(
          '@Rebox-Client:multiple-addresses-data',
          JSON.stringify(ordenedMultipleAddresses),
        );

        const storageUser = localStorage.getItem('@Rebox-Client:signup-data');
        let id = '';
        if (storageUser) {
          id = JSON.parse(storageUser).id;
        }

        for await (const address of ordenedMultipleAddresses) {
          await api.post('/users/address', {
            users_id: id,
            country: address.country,
            state: address.state,
            city: address.city,
            neighborhood: address.district,
            zip_code: address.cep_value,
            street: address.street,
            number: address.number,
            complement: address.complement,
            isMultipleAddress: isUniqueAddress,
          });
        }
      }

      setIsLoading(currLoading => !currLoading);
      push('/cadastro/servicos');
    } catch (error: any) {
      setIsLoading(currLoading => !currLoading);

      if (error instanceof Yup.ValidationError) {
        const { errors } = error;
        notify(errors[0], 'error');
      } else {
        notify(error.response.data.error, 'error');
      }
    }
  };

  const handleSetAddresses = async (
    cepText: string,
    setState: (param: IAddressResponse) => void,
  ) => {
    if (cepText.length !== 8) {
      return;
    }
    const { data: addressViaCepResponse } = await apiViaCep.get(
      `/${cepText}/json`,
    );
    setState(addressViaCepResponse);
  };

  const handleGetAddressByZipcode = async ({ value, name }: any) => {
    try {
      const cepText = formatText.removeAllNonDigits(value);
      if (name === 'unique-cep') {
        handleSetAddresses(cepText, setUniqueAddress);
      } else if (name === 'principal-cep') {
        if (cepText.length !== 8) {
          return;
        }
        const { data: addressViaCepResponse } = await apiViaCep.get(
          `/${cepText}/json`,
        );
        setFirstMultipleAddress(addressViaCepResponse);
      } else {
        if (cepText.length !== 8) {
          return;
        }
        const { data: addressViaCepResponse } = await apiViaCep.get(
          `/${cepText}/json`,
        );
        setMultipleCeps(prevCep => ({
          ...prevCep,
          street: addressViaCepResponse.logradouro,
          city: addressViaCepResponse.localidade,
          state: addressViaCepResponse.uf,
          district: addressViaCepResponse.bairro,
          complement: addressViaCepResponse.complemento,
        }));
      }
    } catch (error: any) {
      notify(
        'Houve um erro ao buscar o cep, por favor, tente novamente.',
        'error',
      );
    }
  };

  const handleFormEnabled = (
    value: any,
    setState: (status: boolean) => void,
  ) => {
    const formattedValue = value;
    if (formattedValue.length === 8) {
      setState(true);
    } else {
      setState(false);
    }
  };

  const handleMultipleCeps = (value: string, name: string) => {
    setMultipleCeps(prevCep => ({
      ...prevCep,
      [name]: value,
    }));
  };

  const handleAddAddress = (event: any) => {
    event.preventDefault();
    if (multipleCeps.cep_value === '') {
      notify('Por favor, insira um CPF!', 'error');
    } else {
      setMultipleAddresses(prevAddress => [
        ...multipleAddresses,
        {
          ...multipleCeps,
          cep_id: prevAddress[prevAddress.length - 1].cep_id + 1,
        },
      ]);
      setMultipleCeps({
        cep_id: 0,
        cep_value: '',
        street: '',
        city: '',
        number: '',
        complement: '',
        state: '',
        district: '',
      });
      setIsFormModalAddressEnabled(false);
      setIsOpenAddressesModal(prevStatus => !prevStatus);
      setFirstMultipleCep(
        formatText.removeAllNonDigits(multipleAddresses[0]?.cep),
      );
      handleFormEnabled(
        formatText.removeAllNonDigits(multipleAddresses[0]?.cep),
        setIsFormAddressEnabled,
      );
      handleGetAddressByZipcode({
        name: 'principal-cep',
        value: formatText.removeAllNonDigits(multipleAddresses[0]?.cep),
      });
      notify('Endereço adicionado com sucesso', 'sucess');
    }
  };

  useEffect(() => {
    localStorage.removeItem('@Rebox-Client:unique-address-data');
    localStorage.removeItem('@Rebox-Client:multiple-address-data');
  }, []);

  return (
    <Container>
      <HeaderBack />
      <Content>
        <ImageLogotipo width={200} height={50} />
        <ContentTitle>
          Agora cadastre o endereço da sua base de operações
        </ContentTitle>
        <ContentAllCards>
          <ContentAllCardsCardItem>
            <Card
              isUniqueAddress={!isUniqueAddress}
              onClick={() => setIsUniqueAddress(prevState => !prevState)}
              style={{ pointerEvents: !isUniqueAddress ? 'none' : 'auto' }}
            >
              <p>Base única</p>
            </Card>
          </ContentAllCardsCardItem>
          <ContentAllCardsCardItem>
            <Card
              isUniqueAddress={isUniqueAddress}
              onClick={() => setIsUniqueAddress(prevState => !prevState)}
              style={{ pointerEvents: isUniqueAddress ? 'none' : 'auto' }}
            >
              <p>Multiplas bases</p>
            </Card>
          </ContentAllCardsCardItem>
        </ContentAllCards>
        <ContentFormAddress>
          {!isUniqueAddress ? (
            <ContentFormAddressForm
              ref={formAddress}
              onSubmit={handleSubmitFormAddress}
            >
              <InputMask
                name="unique-cep"
                placeholder="CEP"
                mask="99999-999"
                value={uniqueCep}
                onChange={({ target }) => {
                  const cep = formatText.removeAllNonDigits(target.value);
                  setUniqueCep(cep);
                  handleFormEnabled(cep, setIsFormAddressEnabled);
                  handleGetAddressByZipcode(target);
                }}
              />
              {isformAddressEnabled && (
                <>
                  <InputText
                    name="street"
                    placeholder="Rua"
                    value={uniqueAddress?.logradouro}
                  />
                  <InputText
                    name="district"
                    placeholder="Bairro"
                    value={uniqueAddress?.bairro}
                  />
                  <InputText
                    name="city"
                    placeholder="Cidade"
                    value={uniqueAddress?.localidade}
                  />
                  <InputText
                    name="state"
                    placeholder="Estado"
                    value={uniqueAddress?.uf}
                  />
                  <InputText
                    name="number"
                    placeholder="Número"
                    maxLength={6}
                    onChange={({ target }) => {
                      setUniqueAddress(prevAddress => ({
                        ...prevAddress,
                        number: target.value,
                      }));
                    }}
                  />
                  <InputText
                    name="complement"
                    placeholder="Complemento"
                    onChange={({ target }) => {
                      setUniqueAddress(prevAddress => ({
                        ...prevAddress,
                        complemento: target.value,
                      }));
                    }}
                  />
                </>
              )}
              <ButtonBlueGradient loading={isLoading}>
                Continuar
              </ButtonBlueGradient>
            </ContentFormAddressForm>
          ) : (
            <ContentFormAddressForm
              ref={formAddress}
              onSubmit={handleSubmitFormAddress}
            >
              <ContentSubtitle style={{ margin: 0, textAlign: 'start' }}>
                Base Principal
              </ContentSubtitle>
              <InputMask
                mask="99999-999"
                name="principal-cep"
                placeholder="CEP"
                value={firstMultipleCep}
                onChange={({ target }) => {
                  const cep = formatText.removeAllNonDigits(target.value);
                  setFirstMultipleCep(cep);
                  handleFormEnabled(cep, setIsFormAddressEnabled);
                  handleGetAddressByZipcode(target);
                }}
              />
              {isformAddressEnabled && (
                <>
                  <InputText
                    name={`street-principal`}
                    placeholder="Rua"
                    value={firstMultipleAddress.logradouro}
                  />
                  <InputText
                    name={`district-principal`}
                    placeholder="Bairro"
                    value={firstMultipleAddress.bairro}
                  />
                  <InputText
                    name={`city-principal`}
                    placeholder="Cidade"
                    value={firstMultipleAddress.localidade}
                  />
                  <InputText
                    name={`state-principal`}
                    placeholder="Estado"
                    value={firstMultipleAddress.uf}
                  />
                  <InputText
                    name={`number-principal`}
                    placeholder="Número"
                    onChange={({ target }) => {
                      setFirstMultipleAddress((prevAddress: any) => ({
                        ...prevAddress,
                        number: target.value,
                      }));
                    }}
                  />
                  <InputText
                    name={`complement-principal`}
                    placeholder="Complemento"
                    onChange={({ target }) => {
                      setFirstMultipleAddress((prevAddress: any) => ({
                        ...prevAddress,
                        complemento: target.value,
                      }));
                    }}
                  />
                </>
              )}
              <ContentSubtitle
                style={{ textAlign: 'start', fontSize: '16px', margin: 0 }}
              >
                Tem mais de uma base de operações?
              </ContentSubtitle>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ButtonOutline
                  onClick={event => {
                    event.preventDefault();
                    setIsOpenAddressesModal(true);
                    setMultipleAddresses([
                      ...multipleAddresses,
                      { ...firstMultipleAddress, cep_id: 1 },
                    ]);
                    setFirstMultipleAddress({
                      cep_id: 1,
                      cep: '',
                      logradouro: '',
                      complemento: '',
                      bairro: '',
                      localidade: '',
                      uf: '',
                      number: '',
                    });
                    setFirstMultipleCep('');
                    setIsFormAddressEnabled(false);
                  }}
                >
                  Adicionar
                </ButtonOutline>
                <ButtonOutline
                  onClick={event => {
                    event.preventDefault();
                    setMultipleAddresses(prevAddress =>
                      prevAddress.filter(
                        address =>
                          address.cep_id !==
                          prevAddress[prevAddress.length - 1].cep_id,
                      ),
                    );
                  }}
                >
                  Remover último endereço
                </ButtonOutline>
              </div>
              <ButtonBlueGradient
                loading={isLoading}
                onClick={() => {
                  setMultipleAddresses([
                    ...multipleAddresses,
                    { ...firstMultipleAddress, cep_id: 1 },
                  ]);
                }}
              >
                Continuar
              </ButtonBlueGradient>
            </ContentFormAddressForm>
          )}
          <ImageHouse />
        </ContentFormAddress>
      </Content>
      {isOpenAddressesModal && (
        <GenericModal
          ariaHideApp={false}
          onRequestClose={() => setIsOpenAddressesModal(prev => !prev)}
          contentLabel="AddressesModal"
          isOpen={isOpenAddressesModal}
        >
          <ContentFormAddressForm
            ref={formAddress}
            onSubmit={handleSubmitFormAddress}
          >
            <ButtonDefault
              iconLeft={IoClose}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                maxWidth: 50,
                padding: 0,
              }}
              onClick={() => setIsOpenAddressesModal(prev => !prev)}
            />
            <SectionsCepOptions>
              <ContentTitle style={{ margin: 0 }}>
                Para prosseguir, preencha os dados de endereço corretamente.
              </ContentTitle>
              <ContentSubtitle style={{ margin: 0 }}>
                Base adjacente
              </ContentSubtitle>
              <InputMask
                mask="99999-999"
                name="cep_value"
                placeholder="CEP"
                value={multipleCeps.cep_value}
                onChange={({ target }) => {
                  const cep = formatText.removeAllNonDigits(target.value);
                  handleFormEnabled(cep, setIsFormModalAddressEnabled);
                  handleMultipleCeps(cep, target.name);
                  handleGetAddressByZipcode(target);
                }}
              />
              {isformModalAddressEnabled && (
                <>
                  <InputText
                    name="street"
                    placeholder="Rua"
                    value={multipleCeps.street}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                  <InputText
                    name="district"
                    placeholder="Bairro"
                    value={multipleCeps.district}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                  <InputText
                    name="city"
                    placeholder="Cidade"
                    value={multipleCeps.city}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                  <InputText
                    name="state"
                    placeholder="Estado"
                    maxLength={2}
                    value={multipleCeps.state}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                  <InputText
                    name="number"
                    placeholder="Número"
                    maxLength={6}
                    value={multipleCeps.number}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                  <InputText
                    name="complement"
                    placeholder="Complemento"
                    value={multipleCeps.complement.toUpperCase()}
                    onChange={({ target }) => {
                      handleMultipleCeps(target.value, target.name);
                    }}
                  />
                </>
              )}
              <ButtonBlueGradient
                onClick={event => {
                  handleAddAddress(event);
                }}
              >
                Adicionar
              </ButtonBlueGradient>
            </SectionsCepOptions>
          </ContentFormAddressForm>
        </GenericModal>
      )}
    </Container>
  );
};

export default AddressData;
