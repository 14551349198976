// Código referenciado do link abaixo, já que não foi disponibilizado no NPM
// https://github.com/larkintuckerllc/react-currency-input

import React, { KeyboardEvent, useCallback } from 'react';

import { Container } from './styles';

interface IProps {
  className?: string;
  name: string;
  placeholder: string;
  max?: number;
  onValueChange: (name: string, value: number) => void;
  value: number;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: React.FC<IProps> = ({
  className = '',
  name,
  placeholder,
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  value,
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      const { key, keyCode } = event;

      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value?.toString();

      let nextValue: number;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString: string =
          value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);

        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }

      onValueChange(name, nextValue);
    },
    [max, onValueChange, value],
  );

  const valueDisplay = (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Container>
      <input
        name={name}
        className={className}
        placeholder={placeholder}
        inputMode="numeric"
        onKeyDown={handleKeyDown}
        value={valueDisplay === 'R$\u00A00,00' ? '' : valueDisplay}
      />
    </Container>
  );
};

export default CurrencyInput;
