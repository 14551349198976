/* eslint-disable camelcase */
import React, { createContext, useCallback, useContext, useState } from 'react';

interface ISignUpUserDataProps {
  // page 1
  name?: string;
  cnpj?: string;
  cpf?: string;
  birth?: string;
  email?: string;
  cellphone?: string;

  // page 2
  cep?: string;
  address_state?: string;
  address_city?: string;
  address_neighborhood?: string;
  address_street?: string;
  number?: string;
  complement?: string;

  // page 3
  brand?: string;
  model?: string;
  plate?: string;
  year?: string;
  color?: string;
  armored_vehicle?: boolean;

  // page 4
  password?: string;
  confirm_password?: string;
}

interface ISignUpContextData {
  signUpUserData: ISignUpUserDataProps;
  updateSignUpUserData(userData: ISignUpUserDataProps): void;
}

const SignUpContext = createContext<ISignUpContextData>(
  {} as ISignUpContextData,
);

const SignUpProvider: React.FC = ({ children }) => {
  // const [signUpUserData, setSignUpUserData] = useState(
  //   {} as ISignUpUserDataProps,
  // );

  const [signUpUserData, setSignUpUserData] = useState(() => {
    const existSignUpUserData = localStorage.getItem(
      '@Rebox-Client:signup-data',
    );

    if (existSignUpUserData) {
      return JSON.parse(existSignUpUserData);
    }

    return {} as ISignUpUserDataProps;
  });

  const updateSignUpUserData = useCallback((userInfo: ISignUpUserDataProps) => {
    setSignUpUserData(userInfo);
    localStorage.setItem('@Rebox-Client:signup-data', JSON.stringify(userInfo));
  }, []);

  // Quando fizer a ultima requisição apagar esta informação do localStorage

  return (
    <SignUpContext.Provider value={{ signUpUserData, updateSignUpUserData }}>
      {children}
    </SignUpContext.Provider>
  );

  // Fazer as funções da requisição
};

function useSignUp(): ISignUpContextData {
  const context = useContext(SignUpContext);

  if (!context) {
    throw new Error('useSignUp must be used within an SignUpProvider');
  }

  return context;
}

export { SignUpProvider, useSignUp };
