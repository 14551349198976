import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

  width: 100%;
  /* z-index: 10; */

  position: relative;
`;

export const ImageGroup = styled.div`
  flex-grow: 1;

  padding-left: 30px;
`;

export const LinkDefault = styled(Link)`
  > svg {
    @media (max-width: 359.9px) {
      width: 70px;
    }
  }
`;

export const NavigationGroup = styled.nav`
  flex-grow: 4;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;

  padding: 0 30px;
`;

export const Notification = styled.div``;

export const NotificationLink = styled(Link)`
  > svg {
    @media (max-width: 359.9px) {
      width: 16px;
      height: 16px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  height: 100%;
  /* min-width: 150px; */

  padding-right: 30px;

  > svg {
    width: 36px;
    margin-right: 15px;

    @media (max-width: 359.9px) {
      width: 26px;
      height: 26px;
    }
  }
`;

export const UserImage = styled.img`
  margin-right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  @media (max-width: 359.9px) {
    width: 26px;
    height: 26px;
  }
`;

export const UserName = styled.span`
  margin-right: 15px;
  text-transform: capitalize;

  @media (max-width: 359.9px) {
    display: none;
  }
`;

export const ArrowButtonOptions = styled.button`
  > svg {
    transition: 500ms;
  }
  > svg.to_spin {
    transform: rotate(180deg);
  }
`;

export const ExpandedMenu = styled.div`
  display: ${props => (props.className === 'open' ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.colors.background_primary};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.colors.text_gray_15};
  max-height: 200px;
  max-width: 300px;
  width: 40vw;
  padding: 3vh 2vw;
  margin: 25vh auto 0;
  transition: ease-in 400ms;

  position: absolute;
  right: 0;
  top: -100%;

  @media (max-width: 767.9px) {
    width: 60vw;
  }

  > .link-menu {
    display: flex;
    align-items: center;
    padding: 10px 30px;

    border-radius: 10px;

    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    color: ${({ theme }) => theme.colors.text_gray_75};

    > svg {
      width: 18px;
      opacity: 0.7;
      margin-right: 10px;
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.background_secondary_50};
    }
  }
`;

export const LinkMenu = styled(Link)``;

export const ButtonLinkMenu = styled.button`
  width: 100%;
`;
