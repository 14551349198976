import React from 'react';

import { Container } from './styles';

interface TooltipProps {
  className?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ className, children }) => {
  return <Container className={className}>{children}</Container>;
};

export default Tooltip;
