// ./src/config/values.ts

export default {
  day_of_the_week: {
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday',
    array: [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ],
  },

  user: {
    company_size: {
      undefined: 'UNDEFINED',
      autonomous: 'AUTONOMOUS',
      small: 'SMALL',
      medium: 'MEDIUM',
      large: 'LARGE',
      big: 'BIG',
      array: [
        'UNDEFINED' /* Não se aplica */,
        'AUTONOMOUS' /* Para pessoas autônomas que prestam serviço, como uma empresa. */,
        'SMALL' /* Para empresas que possuem de 1 a 20 colaboradores */,
        'MEDIUM' /* Para empresas que possuem de 21 a 100 colaboradores */,
        'LARGE' /* Para empresas que possuem de 101 a 500 colaboradores */,
        'BIG' /* Para empresas que possuem acima de 500 colaboradores */,
      ],
    },
    role: {
      admin: 'admin',
      assistant: 'assistant',
      attendant: 'attendant',
      client: 'client',
      motorist: 'motorist',
      partner: 'partner',
      provider: 'provider',
      array: [
        'admin' /* Perfil que administra o sistema da rebox */,
        'assistant' /* Perfil que auxilia a rebox na parte operacional */,
        'provider' /* Perfil que administra a parte que presta os serviços (prestador) pela rebox */,
        'attendant' /* Perfil que auxilia o prestador na parte operacional */,
        'motorist' /* Perfil que auxilia o prestador no atendimento presencial */,
        'client' /* Perfil capaz de solicitar (comprar) os serviços da rebox */,
        'partner' /* Perfil que divulga e ganha pontos (dinheiro) por indicações */,
      ],
    },
    is_partner: {
      yes: true /* Esse usuário (seja cliente, admin, prestador etc.) está autorizado a acessar o painel de parceiro */,
      no: false /* Não está autorizado a acessar o painel de parceiro */,
    },
    status: {
      active: 'ACTIVE',
      inactive: 'INACTIVE',
      suspended: 'SUSPENDED',
      incomplete: 'INCOMPLETE',
      deleted: 'DELETED',
      array: [
        'ACTIVE' /* Usuário pode acessar e executar suas atividades */,
        'INACTIVE' /* Usuário não pode acessar mais o sistema */,
        'SUSPENDED' /* Usuário pode acessar mas não exerce as atividades */,
        'INCOMPLETE' /* Usuário com os dados incompletos (Não pode acessar, nem ser exibido como cliente) */,
        'DELETED' /* Usuário cancelou a conta, e após 30 dias (A partir do dia de cancelamento) o registro será deletado */,
      ],
    },

    access_level: {
      low: 'LOW',
      normal: 'NORMAL',
      high: 'HIGH',
    },
    person_type: {
      physical_person: 'PHYSICAL_PERSON',
      legal_person: 'LEGAL_PERSON',
    },
    sex: {
      female: 'FEMALE',
      male: 'MALE',
      undefined: 'UNDEFINED', // Não se aplica
    },
  },

  session: {
    type: {
      login: 'login',
      logout: 'logout',
      array: ['login', 'logout'],
    },
  },

  vehicle: {
    status: {
      active: 'active',
      inactive: 'inactive',
      array: ['active', 'inactive'],
    },

    classification: {
      undefined: 'UNDEFINED',
      passenger_car: 'PASSENGER_CAR',
      pickup_van_utilities: 'PICKUP_VAN_UTILITIES',
    },
  },

  product: {
    charge_type: {
      yearly: 'yearly',
      subscription: 'subscription',
      detached: 'detached',
      array: [
        'yearly' /* Tipo de cobrança anual (Uma vez ao ano) */,
        'subscription' /* Tipo de cobrança por assinatura mensal */,
        'detached' /* Tipo de cobrança avulsa (Pagamento único) */,
      ],
    },

    status: {
      active: 'active',
      inactive: 'inactive',
      array: ['active', 'inactive'],
    },

    type: {
      plan: 'plan',
      package: 'package',
      array: [
        'plan' /* O tipo do produto e ser vendido é um plano de assinatura */,
        'package' /* O tipo do produto é um pacote que extende os benefícios do plano contratado */,
      ],
    },

    category: {
      vehicle_assistance: 'vehicle_assistance',
      funeral_assistance: 'funeral_assistance',
      array: [
        'vehicle_assistance' /* Planos da rebox de assistência veicular 24h */,
        'funeral_assistance' /* Planos de assistência funerária */,
      ],
    },

    coverage_level: {
      pcl_0001: {
        car_sizes_allowed: ['PASSENGER_CAR'],
        permitted_vehicle_weight_limit: 2000, // Em até o limite definido em kg
        allowed_vehicle_size_limit: 4.8, // Em até o limite definido em metros
      },
      pcl_0002: {
        car_sizes_allowed: ['PICKUP_VAN_UTILITIES'],
        permitted_vehicle_weight_limit: 3500, // Em até o limite definido em kg
        allowed_vehicle_size_limit: 5.8, // Em até o limite definido em metros
      },
    },
  },

  contract: {
    cycle: {
      single: 'SINGLE' /* Cobrança única */,
      weekly: 'WEEKLY' /* Periodicidade da cobrança semanal */,
      biweekly: 'BIWEEKLY' /* Periodicidade da cobrança quinzenal */,
      monthly: 'MONTHLY' /* Periodicidade da cobrança mensal */,
      quarterly: 'QUARTERLY' /* Periodicidade da cobrança trimestral */,
      semiannually: 'SEMIANNUALLY' /* Periodicidade da cobrança semestral */,
      yearly: 'YEARLY' /* Periodicidade da cobrança anual */,
    },

    form_of_payment: {
      boleto: 'BOLETO',
      credit_card: 'CREDIT_CARD',
      debit_card: 'DEBIT_CARD',
      undefined: 'UNDEFINED',
      transfer: 'TRANSFER',
      deposit: 'DEPOSIT',
      subscription_plan: 'SUBSCRIPTION_PLAN',
      cash: 'CASH',
      array: [
        'BOLETO' /* Pagamento por boleto bancário */,
        'CREDIT_CARD' /* Pagamento por cartão de crédito */,
        'DEBIT_CARD' /* Pagamento por cartão de débito */,
        'UNDEFINED' /* Forma de pagamento desconhecida */,
        'TRANSFER' /* Pagamento por transferência */,
        'DEPOSIT' /* Pagamento por depósito */,
        'SUBSCRIPTION_PLAN' /* Pagamento por plano de assinatura (table contracts) */,
        'CASH' /* Pagamento em dinheiro (Presencialmente) */,
      ],
    },

    status: {
      pending: 'PENDING',
      canceled: 'CANCELED',
      released: 'RELEASED',
      deleted: 'DELETED',
      array: [
        'PENDING' /* Cliente não autorizado abrir um chamado pelo plano */,
        'CANCELED' /* Cliente que cancelou o contrato porém quer permanecer no sistema */,
        'RELEASED' /* Cliente já pode utilizar os usos disponíveis no contrato */,
        'DELETED' /* Cliente cancelou o plano e pediu para cancelar sua conta */,
      ],
    },

    who_gave_discount_type: {
      undefined: 'UNDEFINED',
      partner: 'PARTNER',
      admin: 'ADMIN',
    },

    discount_type: {
      single: 'SINGLE' /* Desconto apenas uma vez */,
      recurrent: 'RECURRENT' /* Desconto recorrente */,
    },
  },

  payments: {
    what_is_being_paid: {
      indications: 'indications',
      contracts: 'contracts',
      called: 'called',
      array: [
        'indications' /* Simboliza a Rebox bonificando por uma indicação */,
        'contracts' /* Simboliza o pagamento da compra de um produto */,
        'called' /* Simboliza o pagmento de um chamado (avulso ou usando um plano) */,
      ],
    },

    form_of_payment: {
      boleto: 'BOLETO',
      credit_card: 'CREDIT_CARD',
      debit_card: 'DEBIT_CARD',
      undefined: 'UNDEFINED',
      transfer: 'TRANSFER',
      deposit: 'DEPOSIT',
      subscription_plan: 'SUBSCRIPTION_PLAN',
      cash: 'CASH',
      array: [
        'BOLETO' /* Pagamento por boleto bancário */,
        'CREDIT_CARD' /* Pagamento por cartão de crédito */,
        'DEBIT_CARD' /* Pagamento por cartão de débito */,
        'UNDEFINED' /* Forma de pagamento desconhecida */,
        'TRANSFER' /* Pagamento por transferência */,
        'DEPOSIT' /* Pagamento por depósito */,
        'SUBSCRIPTION_PLAN' /* Pagamento por plano de assinatura (table contracts) */,
        'CASH' /* Pagamento em dinheiro (Presencialmente) */,
      ],
    },

    status: {
      created: 'CREATED' /* Geração de nova cobrança. */,
      updated:
        'UPDATED' /* Alteração no vencimento ou valor de cobrança existente. */,
      confirmed:
        'CONFIRMED' /* Cobrança confirmada (pagamento efetuado, porém o saldo ainda não foi disponibilizado). */,
      received: 'RECEIVED' /* Cobrança recebida. */,
      overdue: 'OVERDUE' /* Cobrança vencida. */,
      delete: 'DELETED' /* Cobrança removida. */,
      restored: 'RESTORED' /* Cobrança restaurada. */,
      refunded: 'REFUNDED' /* Cobrança estornada. */,
      received_in_cash_undone:
        'RECEIVED_IN_CASH_UNDONE' /* Recebimento em dinheiro desfeito. */,
      chargeback_requested: 'CHARGEBACK_REQUESTED' /* Recebido chargeback. */,
      chargeback_dispute:
        'CHARGEBACK_DISPUTE' /* Em disputa de chargeback (caso sejam apresentados documentos para contestação). */,
      awaiting_chargeback_reversal:
        'AWAITING_CHARGEBACK_REVERSAL' /* Disputa vencida, aguardando repasse da adquirente. */,
    },

    charge_type: {
      single:
        'SINGLE' /* Pagamento único, onde o what_is_being_paid não precisará de outros registros de pagamento */,
      recurrent:
        'RECURRENT' /* Pagamento recorrente, onde a cada ciclo do what_is_being_paid um novo registro é criado */,
      divided:
        'DIVIDED' /* Pagamento divido, que se refere ao what_is_being_paid está sendo com mais de uma forma de pagamento */,
    },

    gateway: {
      asaas: 'ASAAS',
      e_rede: 'E_REDE',
      array: ['ASAAS', 'E_REDE'],
    },
  },

  rescue: {
    limit_min: 10,
    status: {
      canceled: 'CANCELED',
      paid: 'PAID',
      pending: 'PENDING',
      reject: 'REJECT',
      array: [
        'CANCELED' /* Resgate cancelado pelo parceiro ou pela Rebox */,
        'PAID' /* Resgate foi pago pela Rebox */,
        'PENDING' /* Resgate aguardando pagamento */,
        'REJECT' /* Resgate recusado pela Rebox (Motivo aleatório) */,
      ],
    },
  },

  indication: {
    status: {
      created: 'CREATED',
      canceled: 'CANCELED',
      effective: 'EFFECTIVE',
      array: [
        'CREATED' /* Aguardando cliente indicado efetivar a compra (aguardando receber confirmação do 1º pagamento) */,
        'EFFECTIVE' /* Apto a receber o bônus e as recorrências */,
        'CANCELED' /* Não recebe mais bonificação por esta indicação (nem fixa ou recorrente) */,
      ],
    },
  },

  recover_password: {
    status: {
      in_progress: 'in_progress',
      released: 'released',
      array: ['in_progress', 'released'],
    },
  },

  bank_account: {
    pix_type: {
      email: 'EMAIL',
      cpf: 'CPF',
      cnpj: 'CNPJ',
      cellphone: 'CELLPHONE',
      others: 'OTHERS',
    },

    type: {
      pix: 'PIX',
      current_account: 'CURRENT_ACCOUNT',
      savings_account: 'SAVINGS_ACCOUNT',
    },

    bank_name: {
      banco_brasil: '001 – BANCO DO BRASIL S.A.',
      santander: '033 – BANCO SANTANDER (BRASIL) S.A.',
      caixa: '104 – CAIXA ECONÔMICA FEDERAL',
      itau: '341 - BANCO ITAÚ S.A.',
      bradesco: '237 - BANCO BRADESCO S.A.',
      mercantil: '389 - BANCO MERCANTIL DO BRASIL S.A.',
      banco_safra: '422 - BANCO SAFRA S.A.',
      banco_rural: '453 - BANCO RURAL S.A.',
      itau_unibanco: '652 - ITAÚ UNIBANCO HOLDING S.A.',
      citibank: '745 - BANCO CITIBANK S.A.',
      banrisul: '041 - BANRISUL S.A.',
      nubank: '260 - NU PAGAMENTOS S.A.',
      pagseguro: '290 - PAG SEGURO INTERNET S.A.',
      picpay: '380 - PICPAY SERVIÇOS S.A.',
      mercado_pago: '323 - MERCADO PAGO',
      cielo: '323 - Mercado Pago',
      inter: '077 - BANCO INTER S.A.',
      original: '212 - BANCO ORIGINAL S.A.',
    },
  },

  setting: {
    commission_transfer: {
      undefined: 'UNDEFINED',
      client: 'CLIENT',
      array: ['UNDEFINED', 'CLIENT'],
    },
  },
};
