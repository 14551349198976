import { Form } from '@unform/web';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { appearScreenFromRight } from '@styles/global';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_primary};
  flex: 1;
  width: 100%;

  animation: ${appearScreenFromRight} 1s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  padding: 0 20px;

  @media (min-width: 1024px) {
    padding: 0;
  }

  @media (max-width: 767.9px) {
    padding: 0 5vw;
  }

  @media (max-width: 479.9px) {
    padding: 0 5vw;
  }
`;

export const ContentTitle = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.subtitle}px;
  line-height: 39px;

  color: ${({ theme }) => theme.colors.text_secondary};

  margin-top: 60px;
`;

export const ContentSubtitle = styled.h2`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.paragraphBig}px;
  line-height: 29px;
  color: ${({ theme }) => theme.colors.text_gray_75};

  max-width: 805px;

  margin-top: 22px;
`;

export const ContentFormPrices = styled.section`
  width: 100%;
  height: 100%;

  display: block;
  justify-content: center;
  align-items: center;

  margin: 8vh 0;

  > form {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  > svg {
    display: none;
    width: 100%;
    max-width: 400px;
    max-height: 400px;
    margin-top: 40px;
  }

  @media (min-width: 768px) {
    display: flex;

    > form {
      max-width: 420px;
    }

    > svg {
      display: block;
      width: auto;
      margin-top: 0px;
      margin-left: 63px;
    }
  }
`;

export const ContentFormPricesForm = styled(Form)``;

export const ContentFormPricesFormTermsOfUse = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_gray};
`;

export const ContentAllCards = styled.div`
  display: flex;
  margin-top: 4vh;
`;

export const ContentAllCardsCardItem = styled.div`
  margin-right: 2vw;
`;

export const InternalLink = styled(Link)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;

export const GenericModal = styled(Modal)`
  background-color: ${({ theme }) => theme.rebox.colors.white.main};
  border-radius: 10px;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid ${({ theme }) => theme.rebox.colors.black.opacity.low};
  min-height: 600px;
  width: 90vw;
  margin: 10vh auto 0;
  position: relative;

  @media (max-width: 539.9px) {
    width: 90%;
    padding: 6vh 6vw;
  }
`;

export const SectionsCepOptions = styled.div`
  padding: 4vh 8vw;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    padding: 4vh 16vw;
  }
`;
