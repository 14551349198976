/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ImageLogotipo } from '@assets/images';
import { ButtonBlueGradient, HeaderBack } from '@components/index';
import api from '@services/api';
import notify from '@utils/notify/toasts';

import { tableContent, tableResumedContent } from './data';
import PriceItem from './PriceItem';
import { IServiceData } from './typing';

import {
  Container,
  Content,
  ContentTitle,
  ContentFormPrices,
  ContentFormPricesForm,
} from './styles';

const PricesData: React.FC = () => {
  const { push } = useHistory();

  const [services, setServices] = useState<IServiceData>({} as IServiceData);
  const [prices, setPrices] = useState<any>({});

  const submitPrices = async () => {
    try {
      const servicesAndPricesData = {
        ...services.openingHours,
        ...prices,
        selectedHourType: services.selectedHourType,
      };

      const storagedUser = localStorage.getItem('@Rebox-Client:signup-data');
      let id = '';
      if (storagedUser) {
        id = JSON.parse(storagedUser).id;
      }

      for await (const activeService of services.selectedServices) {
        await api.post('/users/services', {
          users_id: id,
          name: activeService.label,
          ...prices[activeService.label],
          ...services.openingHours,
          hour_type: services.selectedHourType,
        });
      }

      localStorage.setItem(
        '@Rebox-Client:all-prices-data',
        JSON.stringify(servicesAndPricesData),
      );

      push('/cadastro/senha');
    } catch (error: any) {
      notify(error.message, 'error');
    }
  };

  const handlePrices = (name: string, value: string, label: string) => {
    setPrices((prevPrices: any) => ({
      ...prevPrices,
      [label]: {
        ...prevPrices[label],
        [name]: Number(value),
      },
    }));
  };

  useEffect(() => {
    let storageServices = null;
    storageServices = localStorage.getItem('@Rebox-Client:services-data');
    if (storageServices) {
      storageServices = JSON.parse(storageServices);
      setServices(storageServices);
    }
    localStorage.removeItem('@Rebox-Client:all-prices-data');
  }, []);

  return (
    <Container>
      <HeaderBack />
      <Content>
        <ImageLogotipo width={200} height={50} />
        <ContentTitle>
          Informe a tabela de preços dos serviços selecionados anteriormente
        </ContentTitle>
        <ContentFormPrices>
          <ContentFormPricesForm ref={() => []} onSubmit={() => []}>
            {services.selectedServices?.map(service => (
              <PriceItem
                key={service.label}
                tableContent={
                  service.label.includes('reboque')
                    ? tableContent
                    : tableResumedContent
                }
                label={service.label}
                text={service.text}
                handlePrices={handlePrices}
              />
            ))}
          </ContentFormPricesForm>
          <ButtonBlueGradient
            style={{ maxWidth: '50%', marginTop: '20px' }}
            onClick={event => {
              event.preventDefault();
              submitPrices();
            }}
          >
            Continuar
          </ButtonBlueGradient>
        </ContentFormPrices>
      </Content>
    </Container>
  );
};

export default PricesData;
