import React, { ButtonHTMLAttributes } from 'react';

import { Container, Text } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  icon?: React.ReactSVG;
};

const ButtonBlueGradient: React.FC<ButtonProps> = ({
  children,
  icon,
  loading,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {icon}

      {loading ? <Text>Carregando...</Text> : <Text>{children}</Text>}
    </Container>
  );
};

export default ButtonBlueGradient;
