import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { ImageTow, ImageLogotipo } from '@assets/images';
import {
  ButtonBlueGradient,
  HeaderBack,
  InputSelect,
  InputMask,
} from '@components/index';
import InputCheckBox from '@components/inputs/InputCheckBox';
import { ConfigStyles } from '@config/index';
import notify from '@utils/notify/toasts';

import { daysOfWeek, optionsSelect, services } from './data';
import {
  schemaServices,
  schemaSelectedHourType,
  schemaHours,
} from './schemaValidation';

import {
  Container,
  Content,
  ContentSubtitle,
  ContentTitle,
  ContentFormServices,
  ContentFormServicesForm,
} from './styles';

const ServicesData: React.FC = () => {
  const { push } = useHistory();

  const [selectedHourType, setSelectedHourType] = useState<string>('');

  const [openingHours, setOpeningHours] = useState<any>({
    util_day: '',
    saturday: '',
    sunday: '',
  });
  const [avaliableServices, setAvaliableServices] = useState<any>({
    reboque_car: {
      text: '',
      value: false,
    },
    reboque_moto: {
      text: '',
      value: false,
    },
    reboque_suv: {
      text: '',
      value: false,
    },
    drypan: {
      text: '',
      value: false,
    },
    keychain: {
      text: '',
      value: false,
    },
    battery_recharge: {
      text: '',
      value: false,
    },
    tire_change: {
      text: '',
      value: false,
    },
  });

  const handleOpeningHours = (name: string, value: string) => {
    setOpeningHours((prevHours: any) => ({
      ...prevHours,
      [name]: value,
    }));
  };

  const handleChecked = (name: string, checked: boolean, innerText: string) => {
    setAvaliableServices((prevServices: any) => ({
      ...prevServices,
      [name]: {
        value: checked,
        text: checked ? innerText : '',
      },
    }));
  };

  const handleSubmitServices = async () => {
    try {
      await schemaServices.validate(avaliableServices, { abortEarly: false });
      await schemaSelectedHourType.validate(selectedHourType, {
        abortEarly: false,
      });
      if (selectedHourType === 'fixed_hour') {
        await schemaHours.validate(openingHours, { abortEarly: false });
      }

      const selectedServices = Object.entries(avaliableServices)
        .map((service: [string, any]) => ({
          label: [service[0]][0],
          value: service[1].value,
          text: service[1].text,
        }))
        .filter(selectedService => selectedService.value);

      const servicesData = {
        selectedHourType,
        selectedServices,
        openingHours,
      };

      localStorage.setItem(
        '@Rebox-Client:services-data',
        JSON.stringify(servicesData),
      );

      push('/cadastro/precos');
    } catch (error: any) {
      notify(error.message, 'error');
    }
  };

  useEffect(() => {
    localStorage.removeItem('@Rebox-Client:services-data');
  }, []);

  return (
    <Container>
      <HeaderBack />
      <Content>
        <ImageLogotipo width={200} height={50} />
        <ContentTitle>
          Selecione os serviços que irá prestar para a Rebox!
        </ContentTitle>
        <ContentFormServices>
          <ContentFormServicesForm
            ref={() => []}
            onSubmit={handleSubmitServices}
            style={{ textAlign: 'start' }}
          >
            <ContentSubtitle
              style={{
                fontSize: '1.3rem',
                margin: 0,
              }}
            >
              Selecione um ou mais serviços
            </ContentSubtitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {services.map(service => (
                <InputCheckBox
                  style={{
                    color: avaliableServices[service.name]
                      ? ConfigStyles.rebox.colors.blue.main
                      : ConfigStyles.rebox.colors.black.opacity.high,
                  }}
                  key={service.id}
                  id={service.label}
                  name={service.name}
                  defaultChecked={false}
                  onChange={({ target }) => {
                    handleChecked(target.name, target.checked, target.id);
                  }}
                />
              ))}
            </div>
            <ContentSubtitle
              style={{
                fontSize: '1.3rem',
                margin: 0,
              }}
            >
              Horário de funcionamento
            </ContentSubtitle>
            <InputSelect
              name="hours"
              options={optionsSelect}
              placeholder="Selecione um tipo de horário"
              onChange={({ target }) => {
                setSelectedHourType(target.value);
                if (target.value === '24_hour') {
                  setOpeningHours({
                    util_day: '',
                    saturday: '',
                    sunday: '',
                  });
                }
              }}
            />
            {selectedHourType === 'fixed_hour' &&
              daysOfWeek.map(day => (
                <>
                  <ContentSubtitle style={{ fontSize: '1rem' }}>
                    {day.label}
                    <InputMask
                      name={day.value}
                      mask="99h-99h"
                      placeholder="Horário"
                      value={openingHours[day.value]}
                      onChange={({ target }) => {
                        handleOpeningHours(target.name, target.value);
                      }}
                    />
                  </ContentSubtitle>
                </>
              ))}
            <ButtonBlueGradient>Continuar</ButtonBlueGradient>
          </ContentFormServicesForm>
          <ImageTow />
        </ContentFormServices>
      </Content>
    </Container>
  );
};

export default ServicesData;
