/* eslint-disable prettier/prettier */
/* eslint-disable no-return-assign */
import React, { useCallback, useState } from 'react';

import { InputCurrency } from '@components/index';

import { ITableContent } from '../typing';

import { Container, Content, ContentSubtitle, ContentTitle } from './styles';

interface IProps {
  text: string;
  label: string;
  tableContent: ITableContent[];
  handlePrices(name: string, price: string, label: string): void;
}

const PriceItem: React.FC<IProps> = ({
  text,
  tableContent,
  handlePrices,
  label,
}) => {
  const [valueItem, setValueItem] = useState<any>({
    exit_km: 0,
    over_km: 0,
    stopped_hour: 0,
    worked_hour: 0,
  });
  const handleValueChange = useCallback((name, val: number) => {
    const convertedValue = (val / 100).toFixed(2);

    setValueItem((prevVal: any) => ({
      ...prevVal,
      [name]: val,
    }));

    handlePrices(name, convertedValue, label);
  }, []);

  return (
    <Container>
      <Content style={{ textAlign: 'start' }}>
        <ContentTitle style={{ fontSize: '1.3rem', margin: 0 }}>
          {text}
        </ContentTitle>
        {tableContent.map(item => (
          <ContentSubtitle
            key={item.id}
            style={{ margin: 0, fontSize: '1rem' }}
          >
            {item.label}
            <InputCurrency
              name={item.name}
              value={valueItem?.[item.name]}
              onValueChange={handleValueChange}
              placeholder="Digite seu valor"
            />
          </ContentSubtitle>
        ))}
      </Content>
    </Container>
  );
};

export default PriceItem;
