import React from 'react';

import { Route, Switch } from 'react-router-dom';

// Pages
import * as Page from '@pages/index';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Page.PersonalData} />
    <Route exact path="/cadastro" component={Page.PersonalData} />
    <Route exact path="/cadastro/endereco" component={Page.OperationalArea} />
    <Route exact path="/cadastro/servicos" component={Page.ServicesArea} />
    <Route exact path="/cadastro/precos" component={Page.PricesArea} />
    <Route exact path="/cadastro/senha" component={Page.SetPassword} />
    <Route exact path="/cadastro/obrigado" component={Page.SetThanks} />
  </Switch>
);

export default Routes;
