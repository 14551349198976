/* eslint-disable @typescript-eslint/ban-types */
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import { Alert, Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  showIconPassword?: boolean;
  alertVisible?: boolean;
}

const InputText: React.FC<InputProps> = ({
  name,
  containerStyle = {},
  showIconPassword = false,
  alertVisible,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null); // HTMLInputElement - vai dar ao inputRef as propriedades de um input

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // Verifica se o inputRef tem um valor/value. Se tiver preenchido = true. Se tiver vazio = false. !! Tranforma o value em booleano.
    setIsFilled(!!inputRef.current?.value); // inputRef pega o valor direto do Input. document.querySelector('input') e etc.
  }, []);

  const togglePasswordIsVisible = useCallback(() => {
    setPasswordIsVisible(prevState => !prevState);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
    >
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        name={name}
        defaultValue={defaultValue}
        type={showIconPassword && !passwordIsVisible ? 'password' : ''}
        ref={inputRef}
        {...rest}
      />

      {showIconPassword &&
        (passwordIsVisible ? (
          <FiEyeOff
            size={20}
            onClick={togglePasswordIsVisible}
            cursor="pointer"
          />
        ) : (
          <FiEye size={20} onClick={togglePasswordIsVisible} cursor="pointer" />
        ))}

      {alertVisible && (
        <Alert>
          <FiAlertCircle
            color={error ? '#c53030' : 'rgba(29, 29, 29, 0.3)'}
            size={20}
          />
        </Alert>
      )}
    </Container>
  );
};

export default InputText;
