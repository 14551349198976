import { IDayData, IServiceData, IHourData } from './typing';

export const services: IServiceData[] = [
  {
    id: 1,
    name: 'reboque_car',
    label: 'Reboque Leve',
  },
  {
    id: 2,
    name: 'reboque_moto',
    label: 'Reboque Moto',
  },
  {
    id: 3,
    name: 'reboque_suv',
    label: 'Reboque Utilitário',
  },
  {
    id: 4,
    name: 'drypan',
    label: 'Pane Seca',
  },
  {
    id: 5,
    name: 'keychain',
    label: 'Chaveiro Automotivo',
  },
  {
    id: 6,
    name: 'battery_recharge',
    label: 'Recarga de Bateria',
  },
  {
    id: 7,
    name: 'tire_change',
    label: 'Troca de Pneu',
  },
];

export const daysOfWeek: IDayData[] = [
  {
    id: 1,
    value: 'util_day',
    label: 'Segunda à sexta',
  },
  {
    id: 2,
    value: 'saturday',
    label: 'Sabado',
  },
  {
    id: 3,
    value: 'sunday',
    label: 'Domingo',
  },
];

export const optionsSelect: IHourData[] = [
  {
    label: 'Horário fixo',
    value: 'fixed_hour',
  },
  {
    label: '24 horas',
    value: '24_hour',
  },
];
