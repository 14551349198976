import * as Yup from 'yup';

export const schema = Yup.object().shape({
  password: Yup.string().required('Senha obrigatória'),
  confirm_password: Yup.string()
    .required('Confirme sua senha obrigatória')
    .when('password', {
      is: value => !!value.length,
      then: Yup.string().required('Confirme sua senha obrigatória'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password')], 'A senhas nao estão iguais.'),
});
