import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { appearScreenFromRight } from '@styles/global';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.background_primary};
  flex: 1;
  height: 100vh;

  animation: ${appearScreenFromRight} 1s;
`;

export const ButtonHeaderBack = styled.div`
  display: flex;
  align-items: center;
  height: 70px;

  width: 100%;

  padding: 0 3vw;

  @media (min-width: 768px) {
    padding: 0 6vw;
  }
`;

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;

  width: fit-content;

  > svg > path,
  p {
    transition: 180ms ease-in;
  }

  :hover {
    > svg {
      path {
        fill: #1d1d1d;
      }
    }

    p {
      color: #1d1d1d;
    }
  }
`;

export const BackText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;

  color: #07359b;

  margin-left: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 0 auto;

  width: 100%;

  max-width: 650px;

  padding: 0 20px;
  padding-bottom: 20px;

  > svg {
    width: 100%;
  }

  > form {
    width: 60%;

    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 6vh;
  }

  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.subtitle}px;
  line-height: 39px;

  color: #07359b;

  margin-top: 50px;
  margin-bottom: 50px;
`;

export const ForgetPassword = styled.section`
  display: flex;
  justify-content: flex-end;

  margin-top: 5px;
  margin-right: 16px;
`;

export const ForgetPasswordLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  line-height: 10px;

  color: #07359b;

  transition: 180ms ease-in;

  :hover {
    color: #1d1d1d;
  }
`;

export const DontHaveAccountText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: #1d1d1d;
  text-align: center;

  margin-top: 30px;
  margin-bottom: 5px;
`;

export const SignUpLink = styled(Link)`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: #07359b;

  transition: 180ms ease-in;
  margin-bottom: 20px;

  :hover {
    color: #1d1d1d;
  }
`;
