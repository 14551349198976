const capitalizedFirstLetter = (text: string): string => {
  /**
   * Entrada: melancia açucarada
   * Saída: Melancia açucarada
   */
  const firstLetter = text.substr(0, 1);
  const rest = text.substr(1);

  const textFormated = `${firstLetter.toUpperCase()}${rest}`;

  return textFormated;
};

const removeAllNonDigits = (text: any): string => {
  /**
   * Entrada: Alô @ nova 147 da, casa.
   * Saída: 147
   */
  return text.replace(/[^\d]+/g, '');
};

export default {
  capitalizedFirstLetter,
  removeAllNonDigits,
};
