import React from 'react';

import { AppLocaleProvider } from './locale';
import { SignUpProvider } from './signup';
import { AppThemeProvider } from './theme';

const AppProvider: React.FC = ({ children }) => {
  return (
    <SignUpProvider>
      <AppLocaleProvider>
        <AppThemeProvider>{children}</AppThemeProvider>
      </AppLocaleProvider>
    </SignUpProvider>
  );
};

export default AppProvider;
