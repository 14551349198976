import styled from 'styled-components';

export const Container = styled.header`
  position: absolute;
  top: 5vh;
  left: 5vw;

  display: flex;
  align-items: center;
`;

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;

  width: fit-content;

  > svg > path,
  p {
    transition: 180ms ease-in;
  }

  :hover {
    > svg {
      path {
        fill: #1d1d1d;
      }
    }

    p {
      color: #1d1d1d;
    }
  }
`;

export const BackText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;

  color: #07359b;

  margin-left: 15px;
`;
