import React from 'react';

import { useHistory } from 'react-router-dom';

import { IconBack } from '@assets/icons';

import { BackText, ButtonBack, Container } from './styles';

const HeaderBack: React.FC = () => {
  const { goBack } = useHistory();
  return (
    <Container>
      <ButtonBack onClick={goBack}>
        <IconBack width={14} />
        <BackText>Voltar</BackText>
      </ButtonBack>
    </Container>
  );
};

export default HeaderBack;
