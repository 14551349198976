import { ITableContent } from './typing';

export const tableContent: ITableContent[] = [
  {
    id: 1,
    label: 'Saida até 40KM',
    name: 'exit_km',
  },
  {
    id: 2,
    label: 'KM excendente',
    name: 'over_km',
  },
  {
    id: 3,
    label: 'Hora parada',
    name: 'stopped_hour',
  },
  {
    id: 4,
    label: 'Hora trabalhada',
    name: 'worked_hour',
  },
];

export const tableResumedContent: ITableContent[] = [
  {
    id: 1,
    label: 'Saida até 20KM',
    name: 'exit_km',
  },
  {
    id: 2,
    label: 'KM excendente',
    name: 'over_km',
  },
];
