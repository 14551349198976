import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 100%;
  max-width: 180px;

  padding: 0 15px;
  /* max-width: 420px; */

  border: 1px solid ${({ theme }) => theme.colors.text_blue};
  border-radius: 10px;

  > svg {
    width: 14px;
    margin-right: 15px;
  }

  :hover {
    /* background: #07359b; */
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize.paragraph}px;
  color: ${({ theme }) => theme.colors.text_blue};
`;
