import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  background: rgba(244, 245, 249, 0.5);
  color: #000000;

  width: 100%;
  height: 50px;

  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 10px;

  transition: all 0.2s ease-in-out;

  > input {
    flex: 1;
    width: 100%;
    height: 100%;

    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.paragraph}px;
    line-height: 24px;
    color: #000;

    padding: 0px 30px;

    background: transparent;
    border: 0;
    border-radius: 15px;

    ::placeholder {
      color: rgba(29, 29, 29, 0.5);
      font-size: ${({ theme }) => theme.fontSize.paragraph}px;
      text-transform: none;
    }
  }
`;
