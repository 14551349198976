// src/styles/themes/light.ts

// DEFINIÇÃO DE ESTILOS LIGHT
const light = {
  colors: {
    background_primary: '#ffffff',
    background_secondary: '#F4F5F9',
    background_secondary_50: 'rgba(244, 245, 249, 0.5)',
    background_blue_05: 'rgba(7, 53, 155, 0.05)',

    text_primary: '#000000',
    text_blue: '#07359B',
    text_blue_05: 'rgba(7, 53, 155, 0.05)',
    text_secondary: '#07359B',
    text_white: '#ffffff',

    text_gray: '#1D1D1D',
    text_gray_07: 'rgba(29, 29, 29, 0.07)',
    text_gray_15: 'rgba(29, 29, 29, 0.15)',
    text_gray_50: 'rgba(29, 29, 29, 0.5)',
    text_gray_75: 'rgba(29, 29, 29, 0.75)',

    text_red: '#E84118',
  },

  fontFamily: {
    Montserrat: `'Montserrat', sans-serif`,
    Poppins: `'Poppins', sans-serif`,
    Quicksand: `'Quicksand', sans-serif`,
  },

  fontSize: {
    titleBig: 28,
    title: 24,
    subtitleBig: 22,
    subtitle: 20,
    paragraphBig: 18,
    paragraphMed: 16,
    paragraph: 14,
    legend: 12,
  },
  rebox: {
    colors: {
      white: {
        main: '#ffffff',
        opacity: {
          high: 'rgba(255, 255, 255, 0.8)',
          average: 'rgba(255, 255, 255, 0.5)',
          low: 'rgba(255, 255, 255, 0.3)',
          veryLow: 'rgba(255, 255, 255, 0.1)',
        },
      },
      whiteCloud: {
        main: '#F4F5F9',
        opacity: {
          high: 'rgba(244, 245, 249, 0.8)',
          average: 'rgba(244, 245, 249, 0.5)',
          low: 'rgba(244, 245, 249, 0.3)',
          veryLow: 'rgba(244, 245, 249, 0.1)',
        },
      },
      black: {
        main: '#1d1d1d',
        opacity: {
          high: 'rgba(29, 29, 29, 0.75)',
          average: 'rgba(29, 29, 29, 0.5)',
          low: 'rgba(29, 29, 29, 0.15)',
          veryLow: 'rgba(29, 29, 29, 0.07)',
        },
      },
      blue: {
        main: '#07359B',
        opacity: {
          high: 'rgba(7, 53, 155, 0.8)',
          average: 'rgba(7, 53, 155, 0.5)',
          low: 'rgba(7, 53, 155, 0.3)',
          veryLow: 'rgba(7, 53, 155, 0.1)',
        },
      },
      lightBlue: {
        main: '#146da8',
        opacity: {
          high: 'rgba(20, 109, 168, 0.8)',
          average: 'rgba(20, 109, 168, 0.5)',
          low: 'rgba(20, 109, 168, 0.3)',
          veryLow: 'rgba(20, 109, 168, 0.1)',
        },
      },
      red: {
        main: '#e74c3c',
        opacity: {
          high: 'rgba(231, 76, 60, 0.8)',
          average: 'rgba(231, 76, 60, 0.5)',
          low: 'rgba(231, 76, 60, 0.3)',
          veryLow: 'rgba(231, 76, 60, 0.1)',
        },
      },
      orange: {
        main: '#f39c12',
        opacity: {
          high: 'rgba(243, 156, 18, 0.8)',
          average: 'rgba(243, 156, 18, 0.5)',
          low: 'rgba(243, 156, 18, 0.3)',
          veryLow: 'rgba(243, 156, 18, 0.1)',
        },
      },
      orangeCarrot: {
        main: '#e67e22',
        opacity: {
          high: 'rgba(230, 126, 34, 0.8)',
          average: 'rgba(230, 126, 34, 0.5)',
          low: 'rgba(230, 126, 34, 0.3)',
          veryLow: 'rgba(230, 126, 34, 0.1)',
        },
      },
      gray: {
        main: '#2c3e50',
        opacity: {
          high: 'rgba(44, 62, 80, 0.8)',
          average: 'rgba(44, 62, 80, 0.5)',
          low: 'rgba(44, 62, 80, 0.3)',
          veryLow: 'rgba(44, 62, 80, 0.1)',
        },
      },
      purple: {
        main: '#8e44ad',
        opacity: {
          high: 'rgba(142, 68, 173, 0.8)',
          average: 'rgba(142, 68, 173, 0.5)',
          low: 'rgba(142, 68, 173, 0.3)',
          veryLow: 'rgba(142, 68, 173, 0.1)',
        },
      },
      greenEmerald: {
        main: '#2ecc71',
        opacity: {
          high: 'rgba(46, 204, 113, 0.8)',
          average: 'rgba(46, 204, 113, 0.5)',
          low: 'rgba(46, 204, 113, 0.3)',
          veryLow: 'rgba(46, 204, 113, 0.1)',
        },
      },
      greenSea: {
        main: '#16a085',
        opacity: {
          high: 'rgba(22, 160, 133, 0.8)',
          average: 'rgba(22, 160, 133, 0.5)',
          low: 'rgba(22, 160, 133, 0.3)',
          veryLow: 'rgba(22, 160, 133, 0.1)',
        },
      },
      yellowSunFlower: {
        main: '#f1c40f',
        opacity: {
          high: 'rgba(241, 196, 15, 0.8)',
          average: 'rgba(241, 196, 15, 0.5)',
          low: 'rgba(241, 196, 15, 0.3)',
          veryLow: 'rgba(241, 196, 15, 0.1)',
        },
      },
      yellowRisenShine: {
        main: '#fbc531',
        opacity: {
          high: 'rgba(251, 197, 49, 0.8)',
          average: 'rgba(251, 197, 49, 0.5)',
          low: 'rgba(251, 197, 49, 0.3)',
          veryLow: 'rgba(251, 197, 49, 0.1)',
        },
      },
    },
    fonts: {
      family: {
        Montserrat: `'Montserrat', sans-serif`,
        Poppins: `'Poppins', sans-serif`,
        Quicksand: `'Quicksand', sans-serif`,
      },
      size: {
        title: {
          extraLarge: 40,
          big: 38,
          large: 36,
          normal: 34,
          medium: 32,
          small: 28,
          tiny: 24,
        },
        subtitle: {
          extraLarge: 26,
          big: 24,
          large: 22,
          normal: 20,
          medium: 18,
          small: 16,
          tiny: 14,
        },
        paragraph: {
          extraLarge: 18,
          big: 16,
          large: 14,
          normal: 12,
          medium: 10,
          small: 8,
          tiny: 6,
        },
      },
    },
  },
};

export default light;
