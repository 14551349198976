/* eslint-disable no-console */
import React, { useCallback, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { ImageLogotipo, ImageSecureLogin } from '@assets/images';
import { ButtonBlueGradient, HeaderBack, InputText } from '@components/index';
import { ConfigValues } from '@config/index';
import { useSignUp } from '@hooks/signup';
import User from '@models/User';
import api from '@services/api';
import { getValidationErrors } from '@utils/index';
import notify from '@utils/notify/toasts';

import { schema } from './schemaValidation';
import { IRequest } from './typing';

import {
  Container,
  Content,
  ContentFormContainer,
  ContentSubtitle,
  ContentTitle,
} from './styles';

const SetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signUpUserData, updateSignUpUserData } = useSignUp();
  const { push } = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (data: IRequest) => {
      try {
        setLoading(prevState => !prevState);

        formRef.current?.setErrors({});

        await schema.validate(data, {
          abortEarly: false,
        });

        notify('Aguarde, estamos finalizando o seu cadastro...', 'info');

        const storagedUser = localStorage.getItem('@Rebox-Client:signup-data');
        let id = '';
        if (storagedUser) {
          id = JSON.parse(storagedUser).id;
        }

        await api.put(`/users/${id}/password`, {
          password: data.password,
        });

        sessionStorage.setItem('@Rebox:User:id', id || '');

        push('/cadastro/obrigado');
      } catch (error: any) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
        } else if (error.response.data)
          notify(error.response.data.error, 'error');
      } finally {
        setLoading(prevState => !prevState);
      }
    },
    [signUpUserData],
  );

  return (
    <Container>
      <HeaderBack />

      <Content>
        <ImageLogotipo width={200} height={50} />

        <ContentTitle>Estamos a um passo de concluir</ContentTitle>

        <ContentSubtitle>
          O acesso à plataforma é a combinação do seu e-mail com uma senha.
          Defina abaixo uma senha segura que seja fácil de memorizar.
        </ContentSubtitle>

        <ContentFormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputText
              name="password"
              placeholder="Defina uma senha"
              alertVisible
              showIconPassword
            />
            <InputText
              name="confirm_password"
              placeholder="Confirme a senha"
              alertVisible
              showIconPassword
            />

            <ButtonBlueGradient style={{ marginTop: 20 }} loading={loading}>
              Continuar
            </ButtonBlueGradient>
          </Form>

          <ImageSecureLogin />
        </ContentFormContainer>
      </Content>
      <ToastContainer />
    </Container>
  );
};

export default SetPassword;
